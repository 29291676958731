<template>
    <div>
        <v-container fluid>

            <v-overlay :value="overlay" opacity="0">
                <v-progress-circular
                        indeterminate
                        size="50"
                        color="primary"
                ></v-progress-circular>
            </v-overlay>

            <v-row>

                <v-col cols="12" lg="3">
                    <Statistics :statistic="statistic"
                                @newconversion="addItem"
                                ref="statistics"
                                :years="years"
                                :loading="loading"/>
                </v-col>

                <v-col cols="12" lg="9">
                    <v-card class="rounded-lg shadow" :loading="overlay" :disabled="overlay">
                        <v-card-title>
                            <div>
                                <v-text-field dense
                                              filled
                                              rounded
                                              v-model="search"
                                              append-icon="mdi-magnify"
                                              placeholder="Recherche..."
                                              class="w-300"
                                              single-line
                                              hide-details/>
                            </div>
                            <v-spacer/>
                            <v-tooltip top color="primary">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon
                                           @click="refresh">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                </template>
                                <span>Actualiser</span>
                            </v-tooltip>
                            <v-tooltip top color="primary">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon
                                           @click="ExportExcelTransactions">
                                        <v-icon>mdi-cloud-download-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Exporter</span>
                            </v-tooltip>

                            <v-btn color="blue" dark class="ml-1" fab small depressed
                                   @click="addItem">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </v-card-title>

                        <v-card-text v-if="loading && transactions.length === 0">
                            <v-skeleton-loader type="table"/>
                        </v-card-text>

                        <v-card-text v-if="!loading && transactions.length ===0"
                                     class="text-center">
                            <NoResult/>
                        </v-card-text>

                        <div v-if="!loading && transactions.length>0">
                            <v-data-table
                                    :headers="headers"
                                    :items="transactions"
                                    :items-per-page="5"
                                    :search="search"
                            >
                                <template v-slot:item.salepoint_accounts_count="{item}">
                                    <v-chip dark color="success" small
                                            @click="openSalepointsDialog(item.id,item.pending_mode)">
                                        <v-icon small left>mdi-map-marker-outline</v-icon>
                                        {{ item.success_transactions }} / {{ item.salepoint_accounts_count }}
                                    </v-chip>
                                </template>

                                <template v-slot:item.sum_points="{item}">
                                    <v-chip dark color="primary" small>
                                        {{ parseInt(item.sum_points) | toCurrency }}
                                    </v-chip>
                                </template>

                                <template v-slot:item.pending_mode="{item}">
                                    <v-icon v-if="item.pending_mode" color="blue">mdi-check-circle</v-icon>
                                    <v-icon v-else color="blue">mdi-circle</v-icon>
                                </template>

                                <template v-slot:item.file="{item}">
                                    <v-btn icon color="primary" @click="download(item.path)">
                                        <v-icon>mdi-download-outline</v-icon>
                                    </v-btn>
                                </template>

                                <template v-slot:item.actions="{ item }">
                                    <div class="d-flex">

                                        <v-tooltip top color="primary">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn :color="!item.unlock_at ? 'primary' : 'success'"
                                                       v-bind="attrs" v-on="on"
                                                       fab
                                                       x-small
                                                       depressed
                                                       :disabled="!item.pending_mode || item.success_transactions === 0"
                                                       @click="[!item.unlock_at ? unlock(item.id) : false]">

                                                    <v-icon v-if="!item.unlock_at" color="white">mdi-cash-lock-open
                                                    </v-icon>
                                                    <v-icon v-else color="white">mdi-cash-check</v-icon>

                                                </v-btn>
                                            </template>
                                            <span v-if="!item.unlock_at">Débloquer les soles</span>
                                            <span v-else>Soldes transférés le : {{ item.unlock_at }}</span>
                                        </v-tooltip>

                                        <v-tooltip top color="primary">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn color="primary" v-bind="attrs" v-on="on" icon
                                                       @click="ExportExcelTransactionSalepoints(item.id)">
                                                    <v-icon size="20">mdi-cloud-download-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Exporter</span>
                                        </v-tooltip>

                                        <v-tooltip top color="primary">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn color="primary" v-bind="attrs" v-on="on" icon
                                                       @click="editItem(item)">
                                                    <v-icon size="20">mdi-pencil-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Modifier</span>
                                        </v-tooltip>

                                    </div>
                                </template>

                            </v-data-table>
                        </div>

                    </v-card>
                </v-col>
            </v-row>


            <FormDialog ref="formDialog" @refresh="getExcelTransactions"/>
            <SalepointsDialog ref="salepointsDialog"/>
            <UnlockDialog ref="unlockDialog" @refresh="getExcelTransactions"/>

        </v-container>
    </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResult from "../../components/NoResult";
import FormDialog from "@/views/excelTransactions/components/FormDialog";
import SalepointsDialog from "@/views/excelTransactions/components/SalepointsDialog";
import UnlockDialog from "@/views/excelTransactions/components/UnlockDialog";
import Statistics from "@/views/excelTransactions/components/Statistics.vue";

export default {
    components: {Statistics, UnlockDialog, SalepointsDialog, FormDialog, NoResult},
    data() {
        return {
            headers: [
                {text: 'Description', value: 'description'},
                {text: 'Somme des points', align: 'center', value: 'sum_points'},
                {text: 'Total POS', align: 'center', value: 'salepoint_accounts_count'},
                {text: 'Mode en attente', align: 'center', value: 'pending_mode'},
                {text: 'Date', value: 'date'},
                {text: 'Fichier Excel', align: 'center', value: 'file'},
                {text: 'Actions', value: 'actions'},
            ],
            transactions: [],
            years: [],
            statistic: {
                transactions_total: 0
            },

            loading: false,
            overlay: false,

            search: '',
            filter: {}
        }
    },
    methods: {
        getExcelTransactions(filter) {
            this.transactions = []
            this.loading = true
            this.$Progress.start()
            this.filter = filter ? filter : this.filter
            HTTP.get('/excel-transactions', {
                params: {
                    filter: this.filter
                }
            }).then((res) => {
                this.loading = false
                this.$Progress.finish()
                this.transactions = res.data.data
                this.statistic = res.data.statistic
                this.years = res.data.years
            }).catch(err => {
                this.$Progress.fail()
                this.loading = false
                console.log(err)
            })
        },
        addItem() {
            this.$refs.formDialog.add()
        },
        editItem(item) {
            this.$refs.formDialog.edit(item)
        },
        openSalepointsDialog(id, pendingMode) {
            this.$refs.salepointsDialog.open(id, pendingMode)
        },
        ExportExcelTransactions() {
            let baseUrl = process.env.VUE_APP_FILE_URL
            let token = this.$store.state.user.token
            let userId = this.$store.state.user.id
            let DownloadURL = baseUrl + '/export/excel/excel-transactions?token=' + token + '&id=' + userId;
            window.open(DownloadURL, '_blanc')
        },
        ExportExcelTransactionSalepoints(id) {
            let baseUrl = process.env.VUE_APP_FILE_URL
            let token = this.$store.state.user.token
            let userId = this.$store.state.user.id
            let DownloadURL = baseUrl + '/export/excel/excel-transaction-salepoints?token=' + token + '&id=' + userId + '&transaction_id=' + id;
            window.open(DownloadURL, '_blanc')
        },
        download(path) {
            let url = process.env.VUE_APP_FILE_URL + path
            window.open(url, '_blanc')
        },
        refresh() {
            this.filter = {}
            this.getExcelTransactions()
            this.$refs.statistics.getTransactionsAnalyticByMonthYear()
        },
        unlock(excelTransactionId) {
            this.$refs.unlockDialog.open(excelTransactionId)
        },
    },
    created() {
        this.getExcelTransactions()
    },
}
</script>

<style scoped>

</style>