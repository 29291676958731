<template>
  <div>
    <v-dialog max-width="500" v-model="dialog" scrollable persistent>
      <v-card :loading="isLoading" :disabled="isLoading">

        <v-card-title>
          {{ dialogTitle }}
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-4">

          <v-alert v-if="action === 'add'"
                   color="info"
                   text
                   prominent
                   border="left">
            Veuillez Respecter ce format excel :

            <span class="d-block py-2">
              <strong>Colonne A :</strong>
              Le code POS
            </span>

            <span class="d-block">
              <strong>Colonne B :</strong>
              Les points
            </span>

            <v-btn depressed dark class="mt-2" color="blue"
                   @click="download">
              <v-icon left>mdi-download-outline</v-icon>
              Exemple
            </v-btn>

          </v-alert>

          <v-text-field v-model="form.description"
                        label="Description *"
                        :error-messages="errors.description"/>

          <v-checkbox v-if="action === 'add'"
                      v-model="form.pending_mode" label="Transfert en mode en attente"/>

          <v-file-input v-if="action === 'add'"
                        show-size
                        small-chips
                        @change="uploadFile"
                        class="mt-3"
                        ref="file"
                        filled
                        rounded
                        label="Sélectionner le fichier excel *"
                        :error-messages="errors.excel_file"/>

        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary"
                 :loading="isLoading"
                 :disabled="isLoading"
                 depressed
                 @click="save">
            <v-icon left>mdi-content-save</v-icon>
            Enregistrer
          </v-btn>
        </v-card-actions>

      </v-card>

    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      action: null,
      isLoading: false,
      dialog: false,
      dialogTitle: '',
      errors: {},
      form: {
        id: '',
        excel_file: '',
        description: '',
        pending_mode: false,
      },
    }
  },
  methods: {
    uploadFile(file) {
      this.form.excel_file = file
    },
    add() {
      this.errors = {}
      this.form = {
        id: '',
        excel_file: '',
        description: '',
        pending_mode: false,
      }
      this.action = 'add'
      this.dialog = true
      this.dialogTitle = 'Ajouter une conversion'
      if (this.$refs.file) {
        this.$refs.file.reset()
      }
    },
    edit(item) {
      this.errors = {}
      this.action = 'edit'
      this.dialog = true
      this.form = {
        id: item.id,
        description: item.description,
      }
      this.dialogTitle = 'Modifier une conversion'
    },
    close() {
      this.dialog = false
    },
    save() {
      this.errors = {}
      this.isLoading = true
      this.$Progress.start()
      let formData = new FormData()

      formData.append('id', this.form.id)
      formData.append('description', this.form.description)
      formData.append('excel_file', this.form.excel_file)
      formData.append('pending_mode', this.form.pending_mode)

      let methode = this.form.id ? 'update' : 'store'

      HTTP.post('/excel-transactions/' + methode, formData).then(() => {

        this.isLoading = false
        this.$Progress.finish()
        this.$successMessage = 'Cette conversion a été enrigistrée avec succés'
        this.$emit('refresh')
        this.close()

      }).catch(err => {
        this.$Progress.fail()
        this.errors = err.response.data.errors
        this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
        this.isLoading = false
        console.log(err)
      })
    },
    download() {
      let path = process.env.VUE_APP_FILE_URL + '/conversion.xlsx'
      window.open(path, '_blanc')
    }
  }
}
</script>

<style scoped>

</style>