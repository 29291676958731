<template>
    <div>
        <v-card class="rounded-lg shadow">
            <v-card-text class="text-center">

                <v-avatar color="primary" size="55">
                    <v-icon dark size="28">mdi-file-restore</v-icon>
                </v-avatar>

                <v-skeleton-loader v-if="loading" class="my-3" type="list-item,text,text"/>

                <div v-else>
                    <h2 class="text--primary my-3">{{ statistic.transactions_total }}</h2>
                    <p class="text-uppercase">Nombre des conversions par excel</p>

                    <v-btn color="blue"
                           dark
                           depressed
                           @click="$emit('newconversion')">
                        <v-icon left>mdi-plus</v-icon>
                        Ajouter
                    </v-btn>

                </div>
            </v-card-text>
        </v-card>

        <v-card class="rounded-lg shadow mt-3">

            <v-card-title>
                Statistique
            </v-card-title>

            <v-divider/>

            <v-card-text v-if="isLoading">
                <v-skeleton-loader type="list-item-three-line"/>
                <v-skeleton-loader type="list-item-three-line"/>
                <v-skeleton-loader type="list-item-three-line"/>
            </v-card-text>

            <v-card-text v-else>
                <v-select dense
                          outlined
                          label="Année"
                          v-model="year"
                          :items="years"
                ></v-select>

                <div v-for="(month,key) in months"
                     :key="key"
                     class="d-flex justify-space-between mb-5 align-center">
                    <v-icon left dense small color="success">mdi-circle</v-icon>
                    {{ key }}
                    <v-spacer/>
                    <strong>
                        {{ month | toCurrency }} DZD
                    </strong>
                </div>

                <v-divider/>

                <h2 class="text-center mt-5 mb-3 text-primary">
                    {{ totalByYear() | toCurrency }} DZD
                </h2>

            </v-card-text>
        </v-card>

    </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
    props: ['statistic', 'loading', 'years'],
    data() {
        return {
            isLoading: false,
            year: new Date().getFullYear(),
            months: null,
        }
    },
    methods: {
        getTransactionsAnalyticByMonthYear() {
            this.isLoading = true
            this.$Progress.start()
            HTTP.get('/excel-transactions/month-year-analytic', {
                params: {
                    year: this.year
                }
            }).then((res) => {
                this.isLoading = false
                this.months = res.data.data
            }).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        },
        totalByYear() {
            if (this.months) {
                let obj = this.months
                return Object.values(obj).reduce((a, b) => a + b, 0)
            }
        }
    },
    created() {
        this.getTransactionsAnalyticByMonthYear()
    },
    watch: {
        year() {
            this.getTransactionsAnalyticByMonthYear()
        }
    }
}
</script>

<style scoped>

</style>