<template>
  <div>
    <v-dialog v-model="dialog" max-width="400" persistent>
      <v-card class="text-center">
        <v-card-text class="pa-6">

          <v-icon color="primary" size="110">mdi-information-outline</v-icon>

          <h2 class="text--primary mt-3">{{ $confirm_dialog_title }}</h2>

          <p class="mt-5 mb-5">
            Êtes-vous sûr de vouloir débloquer les soldes de cette conversion !
          </p>

          <v-btn text class="mr-2"
                 @click="close">
            <v-icon left>mdi-close</v-icon>
            Abnuler
          </v-btn>

          <v-btn color="primary"
                 :loading="isLoading"
                 :disabled="isLoading"
                 depressed
                 @click="unlock">
            <v-icon left>mdi-cash-check</v-icon>
            Débloquer
          </v-btn>

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      isLoading: false,
      dialog: false,
      excelTransactionId: null,
    }
  },
  methods: {
    open(excelTransactionId) {
      this.excelTransactionId = excelTransactionId
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    unlock() {
      this.isLoading = true
      this.$Progress.start()
      HTTP.post('/excel-transactions/unlock', {
        excel_transaction_id: this.excelTransactionId
      }).then(() => {
        this.isLoading = false
        this.$Progress.finish()
        this.$successMessage = 'Cette opération a été enregistrée avec succès.'
        this.close()
        this.$emit('refresh')
        this.close()
      }).catch(err => {
        this.$Progress.fail()
        this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
        this.isLoading = false
        console.log(err)
      })
    },
  }
}
</script>

<style scoped>

</style>